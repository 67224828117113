<template>
    <div class="con-wrap">
        <GoodsRentalViewPopup v-if="pop.isShow" :idx="pop.idx" @close="pop.hideDetail"></GoodsRentalViewPopup>
        <CarrotTitle title="물품대여 신청하기"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div class="tabDefult-col">
                    <router-link to="GoodsRentalList_tab1"><button class="tab-default">월별</button></router-link>
                    <router-link to="GoodsRentalList_tab2"><button class="tab-default on">리스트</button></router-link>
                </div>
                <div class="con-top">
                    <div class="con-top-txt">
                        - 현재 대여중인 물품 현황을 확인하고 예약할 수 있습니다.<br>
                        - 예약 후 경영기획실에서 신청하신 물품을 찾아가시기 바랍니다.<br>
                        - 개인부주의로 인한 분실 및 파손시 자기부담금 : 자산구매일로부터 1년 이하 50%, 3년 이하 30%, 3년 이상 20% (구매액 기준)
                    </div>
                </div>
                <div>

                    <div class="mt-50 mb-20">
                        <table class="table-row table-serach">
                            <tbody>
                                <tr>
                                    <th width="180">분류</th>
                                    <td>
                                        <select name="cat1" id="cat1" class="w-45per mr-10" v-model="rental.cat1" @change="rental.setCat1">
                                            <option value="">중분류 전체</option>
                                            <option :value="irow" v-for="(irow, i) in rental.categories" :key="i">{{ irow.name }}</option>
                                        </select>
                                        <select name="cat2" id="cat2" class="w-45per" v-model="rental.cat2" @change="rental.doSearch">
                                            <option value="">소분류 전체</option>
                                            <option :value="v" v-for="(v, k) in rental.cat1.children" :key="k">{{ v }}</option>
                                        </select>
                                    </td>
                                    <th width="180">대여기간</th>
                                    <td>
                                        <v-datepicker v-model="rental.sdate" class="datepicker-group mr-10">
                                            <template #default="{ inputValue, togglePopover }">
                                                <div>
                                                    <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                    <button class="btn-cal" @click="togglePopover()"></button>
                                                </div>
                                            </template>
                                        </v-datepicker>
                                        ~
                                        <v-datepicker v-model="rental.edate" class="datepicker-group ml-10">
                                            <template #default="{ inputValue, togglePopover }">
                                                <div>
                                                    <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                    <button class="btn-cal" @click="togglePopover()"></button>
                                                </div>
                                            </template>
                                        </v-datepicker>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="180">요청자</th>
                                    <td colspan="3">
                                        <carrot-dept v-model="rental.idx_office" class="w-300px"></carrot-dept>
                                        <carrot-staff :idx_office="rental.idx_office" v-model="rental.idx_hq" class="w-300px ml-10"></carrot-staff>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="rental.doSearch()">검색</button>
                        <div class="clear"></div>
                    </div>

                    <div class="mt-40 mb-20">
                        <select name="state" id="state" class="w-200px" v-model="rental.state" @change="rental.doSearch">
                            <option value="">상태 전체</option>
                            <option value="보유 중">보유 중</option>
                            <option value="예약 중">예약 중</option>
                            <option value="대여 중">대여 중</option>
                            <option value="반납 중">반납 중</option>
                            <option value="연체 중">연체 중</option>
                        </select>
                        <router-link to="GoodsRentalAdd"><button class="btn-default float-right ml-10">물품 예약</button></router-link>
                        <button v-if="rental.isAuth=='Y'" @click="rental.setReturn" class="btn-default float-right ml-10">반납</button>
                        <button v-if="rental.isAuth=='Y'" @click="rental.setRental" class="btn-default float-right">대여</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="50">
                            <col width="80">
                            <col width="*">
                            <col width="200">
                            <col width="220">
                            <col width="200">
                            <col width="160">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <label><input type="checkbox" @change="rental.selectAll" v-model="rental.isAll"></label>
                                </th>
                                <th>No</th>
                                <th>자산번호</th>
                                <th>대여물품</th>
                                <th>대여기간</th>
                                <th>요청자</th>
                                <th>대여 상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in rental.list" :key="i">
                                <td>
                                    <label><input type="checkbox" :id="'listSel-'+i" :value="irow.idx_rental" v-model="rental.selected"></label>
                                </td>
                                <td>{{ irow.num }}</td>
                                <td @click="pop.showDetail(irow)"><span class="btn-view">{{ irow.code }}</span></td>
                                <td>{{ irow.title }}</td>
                                <td>{{ irow.sdate?irow.sdate+' ~ '+irow.edate:'-' }}</td>
                                <td>{{ irow.ename?irow.ename+'('+irow.kname+')':'-' }}</td>
                                <td>{{ irow.state }}</td>
                            </tr>
                            <tr v-if="rental.total==0">
                                <td colspan="7">목록이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="rental.total" :list_per_page="rental.rows" v-model="rental.page" @change="rental.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import { DatePicker } from 'v-calendar'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import GoodsRentalViewPopup from '@/components/popup/myPIMS/GoodsRentalViewPopup.vue'


export default {
    layout:"myPIMS",

    components: {
        GoodsRentalViewPopup,
        'v-datepicker' : DatePicker,
        CarrotDept,
        CarrotStaff 
    },
    setup() {
        const toast = useToast();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false,
            idx   : 0,

            showDetail: (irow) => {
                // console.log(irow);
                pop.isShow = true;
                pop.idx = Number(irow.idx_rental);
            },

            hideDetail: () => {
                rental.doSearch();
                pop.isShow = false;
            },
        });

        const rental = reactive({
            categories : [],
            isMounted  : false,
            isAuth     : 'N',             // 총무팀만 접근 권한

            page   : 1,
            rows   : 20,

            cat1   : "",
            cat2   : "",

            sdate  : "",
            edate  : "",

            idx_office : 0,
            idx_hq       : 0,
            state        : "",

            selected : [],

            list : [], total : 0,

            selectAll : () => {
                if( rental.isAll == true ){
                    for(let i=0; i<rental.list.length; i++){
                        let irow = rental.list[i];

                        rental.selected.push(irow.idx_rental);
                    }
                } else {
                    rental.selected = [];
                }
            },

            setCat1 : () =>{
                rental.cat2 = "";
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        rental.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            setRental : () => {
                if( rental.selected.length <= 0 ){
                    Swal.fire("대여할 물품을 선택하세요.");
                    return;
                }
                let params = {
                    selected : rental.selected
                };
                axios.post('/rest/mypims/setRental', params).then((res) => {
                    if( res.data.err == 0 ){
                        rental.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setReturn : () => {
                if( rental.selected.length <= 0 ){
                    Swal.fire("반납할 물품을 선택하세요.");
                    return;
                }
                let params = {
                    selected : rental.selected
                };
                axios.post('/rest/mypims/setReturn', params).then((res) => {
                    if( res.data.err == 0 ){
                        rental.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch: () => {
                if( rental.isMounted == false ) return;

                let params = {
                    page   : rental.page,
                    rows   : rental.rows,

                    sdate  : rental.sdate,
                    edate  : rental.edate,

                    idx_office : rental.idx_office,
                    idx_hq     : rental.idx_hq,

                    state : rental.state
                };

                if( typeof rental.cat1 == 'undefined' || rental.cat1 == '' ){
                    params.cat1 = '';
                    params.cat2 = '';
                } else {
                    params.cat1 = rental.cat1.name;
                    if( typeof rental.cat2 == 'undefined' || rental.cat2 == '' ){
                        params.cat2 = '';
                    } else {
                        params.cat2 = rental.cat2;
                    }
                }

                axios.get('/rest/mypims/getRentalList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        rental.list  = res.data.list;
                        rental.total = res.data.total;

                        rental.isMounted = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

        });

        onMounted(() => {
            // Mounted
            rental.isMounted = true;

            let td = new Date();
            rental.sdate = td.getFullYear() + "-" + (td.getMonth()<9?'0'+(td.getMonth()+1):td.getMonth()+1) + '-01';
            let ed = new Date(td.getFullYear(), td.getMonth()+1, 0);
            rental.edate = ed.getFullYear() + "-" + (ed.getMonth()<9?'0'+(ed.getMonth()+1):ed.getMonth()+1) + '-' + ed.getDate();

            rental.getCategory();
            rental.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {pop, rental};
    }
}
</script>

<style lang="scss" scoped>
</style>